import React, { FC } from 'react';
import { storyblokEditable } from '@storyblok/react';
import DynamicComponent from './dynamicComponent';
import MetaTags from './meta-tags/meta-tags.component';

export interface Props {
  blok: any;
}

const CaseStudy: FC<Props> = ({ blok }) => {
  const itemsToFilter = ['component', '_uid', 'meta', 'seoMeta', '_editable'];
  const content = Object.keys(blok)
    .filter((x) => !itemsToFilter.includes(x))
    .map((childBlok: any) => {
      const arr: any = [];
      blok[childBlok].forEach((bl: any) => {
        arr.push(<DynamicComponent blok={bl} key={bl._uid} />);
      });
      return arr;
    });

  return content ? (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <MetaTags data={blok.seoMeta} />
      {content}
    </div>
  ) : null;
};

export default CaseStudy;
