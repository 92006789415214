import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { StoryData } from 'storyblok-js-client';
import Layout from '../sb-components/layout';
import { CommonData, GlobalData } from '../common-types';
import StoryblokService from '../utils/storyblok-service';
import CaseStudy from '../sb-components/caseStudy';

interface State {
  pageData?: CommonData;
  globalData: GlobalData;
  path: string;
}

interface Props
  extends PageProps<{
    pageData: StoryData<string>;
    globalData: StoryData<string>;
  }> {}

class CaseStudyPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const pageContext = this.props.pageContext as any;
    this.state = {
      globalData: JSON.parse(props.data.globalData.content),
      path: props.path,
      pageData:
        pageContext?.story?.content && JSON.parse(pageContext?.story?.content),
    };
  }

  async componentDidMount() {
    try {
      const response = await StoryblokService.get(
        `cdn/stories/${this.state.path}`
      );
      this.setState((prevState) => ({
        ...prevState,
        pageData: { ...response.data.story.content },
      }));
      setTimeout(() => StoryblokService.initEditor(this), 200);
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const pageContext = this.props.pageContext as any;
    const path = pageContext?.story?.full_slug;
    const contentString = pageContext?.story?.content;
    const content = contentString && JSON.parse(contentString);
    const seoMeta = content?.seoMeta;
    return (
      <Layout
        className="page page-caseStudy"
        header={this.state.globalData.header}
        footer={this.state.globalData.footer}
        showBlock
        cookiesConsent={this.state.globalData.cookiesConsent}
        full_slug={this.state.path.replace(/(\/)/, '')}
        path={`/${path}`}
      >
        <CaseStudy
          blok={{
            ...this.state.pageData,
            seoMeta,
          }}
        />
      </Layout>
    );
  }
}

export default CaseStudyPage;

export const query = graphql`
  {
    pageData: storyblokEntry(slug: { eq: "caseStudy" }) {
      name
      content
      full_slug
      uuid
    }
    globalData: storyblokEntry(full_slug: { eq: "global" }) {
      name
      content
      full_slug
      uuid
    }
  }
`;
